body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: "Tenor Sans", "sans-serif"; */
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.025em;
  line-height: 1.2;
  -moz-osx-font-smoothing: grayscale;
  animation: page-fade-in-up 0.8s ease forwards;
}

@keyframes page-fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.top-bars {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}

.top-bars.scrolled {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.form-check-input:checked {
  background-color: #2e2e97;
  border-color: #2e2e97;
}
.btn-dark:active {
  background-color: #2e2e97 !important;
  border-color: #2e2e97 !important;
}
.btn-dark:hover {
  background-color: #2e2e97 !important;
  border-color: #2e2e97 !important;
}

/* .modalpopup {
  width: 100% !important;
} */

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 35px !important;
}

@media only screen and (max-width: 430px) {
  .modalpopup {
    width: 300px !important;
  }
}

.slick-dots {
  top: 90%;  
}