.discount-price {
  color: grey;
  font-size: 14px;
  text-decoration: line-through;
}

.allproducts-product-main {
  width: 100%;
}

.allproducts-product-main ul {
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.allproducts-product-main ul li {
  cursor: pointer;
  list-style: none;
  padding: 0px 0px;
  transition: transform 0.3s ease;
  width: 19%;
  border: 0.5px solid #b2b2b242;
  margin: 0px 5px;
  border-radius: 8px;
}

.adingproductocart {
  display: flex;
  justify-content: space-between;
}

.allproducts-product-main ul li a {
  color: #000;
  text-decoration: none;
}
.allproducts-product-main ul li:hover {
  -ms-transform: scale(1.04);
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}


.allproducts-background-div {
  width: 100%;
  display: flex;
  justify-content: end;
}

.tag-name-product {
  color: grey;
  font-size: 12px;
  margin: 5px 0px 0px 0px;
  padding: 0px;
}
.allproducts-background-div span {
  position: absolute;
  background: #000;
  border-radius: 27px;
  font-size: 11px;
  color: #fff;
  padding: 5px 15px;
  opacity: 0.8;
  font-weight: bold;
  rotate: 399deg;
  margin: 20px 0px 0px 32px;
}
.allproducts-product-main ul li img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.allproducts-product-main ul li h5 {
  margin: 0px;
  padding: 0px;
  text-align: start;
  font-weight: 600;
  font-size: 13px;
  padding: 7px 0px;
  letter-spacing: 0.8px;
}

.allproducts-product-main ul li p {
  margin: 0px;
  padding: 0px 0px;
  font-size: 15px;
  font-weight: 600;
  color: #2e2e97;
}

.star-rating .star {
  margin: 0px;
  font-size: 20px;
  color: rgba(255, 184, 0, 1);
}

.allproducts-product-main ul li span {
  /* color:grey; */
  font-size: 12px;
  font-weight: 500;
}
.allproducts-product-main ul li h6 {
  margin: 15px;
  color: grey;
  font-size: 10px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  color: #2e2e97;
}
/* .allproducts-product-main ul li button{
    background-color: #000;
    outline: none;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
} */
.allproducts-product-main ul li svg {
  font-size: 22px;
  margin: 8px;
  color: #2e2e97 !important;
}

.go4109123758 {
  justify-content: end !important;
}

.add-to-cart-button button {
  padding: 6px 15px;
  background-color: #2e2e97;
  font-size: 12px;
  border: 1px solid transparent;
  color: white;
  border-radius: 5px;
}
.add-to-cart-button button:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}

.all-product-sub-heading {
  font-weight: 600;
  margin: 0px 5px;
}

.heading-topsale h3 {
  font-weight: bold;
}

/* responsibve 1024 */
@media only screen and (max-width: 500px) {
  .add-to-cart-button button {
    pad: 3px 10px;
  }
}
/* responsibve 768 */
@media only screen and (max-width: 768px) {
  .search-bar-result {
    padding: 0px;
  }
}
/* responsibve 525 */
@media only screen and (max-width: 525px) {
  .allproducts-background-div span {
    margin: 16px 0px 0px 166px;
  }
}
@media only screen and (max-width: 425px) {
  .allproducts-background-div span {
    margin: 15px 0px 0px 120px;
  }
}
@media only screen and (max-width: 375px) {
  .allproducts-background-div span {
    margin: 15px 0px 0px 95px;
  }
}
@media only screen and (max-width: 330px) {
  .allproducts-background-div span {
    margin: 17px 0px 0px 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .allproducts-product-main ul li {
    width: 19%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .allproducts-product-main ul li {
    width: 23%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .allproducts-product-main ul li {
    width: 31%;
  }
}
@media only screen and (min-width: 523px) and (max-width: 768px) {
  .allproducts-product-main ul li {
    width: 48%;
  }
}
@media only screen and (min-width: 356px) and (max-width: 523px) {
  .allproducts-product-main ul li {
    width: 47%;
  }
  .allproducts-product-main ul li svg {
    font-size: 16px;
    margin: 4px;
  }
  .allproducts-product-main ul li h5 {
    font-size: 12px;
  }
  .allproducts-product-main ul li p {
    font-size: 12px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 356px) {
  .allproducts-product-main ul li {
    width: 46%;
  }
  .allproducts-product-main ul li svg {
    font-size: 16px;
    margin: 4px;
  }
  .allproducts-product-main ul li h6 {
    margin: 10px;
  }
  .allproducts-product-main ul li h5 {
    font-size: 12px;
  }
  .allproducts-product-main ul li p {
    font-size: 12px;
  }
}
