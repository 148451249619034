.bannermiddle_main {
  overflow-x: hidden !important;
}

.left-section {
  /* background-image: url(/public/img/left_section.png); */
  background-repeat: no-repeat;
  padding: 0% 17%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.left-section h6 {
  word-spacing: 1px;
}
.left-section .c {
  width: 70%;
}

.left-section p {
  color: rgba(0, 0, 0, 0.5);
}

.left-section h1 {
  font-weight: bold;
  margin: 22px 0px;
  color: black;
}

.right_section {
  position: relative;
  height: 100%;
}
.right_section img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.videoplay {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  right: 40%;
  cursor: pointer;
}

.videoicon {
  font-size: 40px;
  margin-left: 6px;
}

.left-section button {
  background-color: #2e2e97;
  font-size: 12px;
  padding: 10px 25px;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
}

@media only screen and (max-width: 992px) {
  .left-section {
    background-position: 90%;
  }
}

@media (max-width: 576px) {
  .left-section {
    background-position: 95%;
  }
  .left-section h5 {
    font-size: 14px;
  }
  .left-section h1 {
    font-size: 20px;
  }
  .left-section p {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .left-section {
    background-position: 95%;
  }
  .left-section h5 {
    font-size: 12px;
  }
  .left-section h1 {
    font-size: 18px;
  }
  .left-section p {
    font-size: 11px;
  }
  .left-section button {
    padding: 7px 12px;
  }
}
@media (max-width: 350px) {
  .left-section {
    background-position: 95%;
  }
  .left-section h5 {
    font-size: 11px;
  }
  .left-section h1 {
    font-size: 13px;
  }
  .left-section p {
    font-size: 10px;
  }
  .left-section button {
    padding: 7px 12px;
  }
}

@media only screen and (max-width: 768px) {
  .left-section {
    padding: 4% 17%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .left-section {
    background-position: 97%;
  }
  .left-section .c {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .left-section .c {
    width: 50%;
  }
}
@media only screen and (min-width: 260px) and (max-width: 600px) {
  .left-section .c {
    width: 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .left-section {
    padding: 12% 7% !important;
  }
}
