.discount-price-category {
  color: grey;
  font-size: 14px;
  text-decoration: line-through;
}

.category-product-main {
  width: 100%;
}

.category-product-main ul {
  padding: 0px;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 0px;
}
.category-product-main ul li {
  position: relative;
  width: 19%;
  list-style: none;
  cursor: pointer;
  transition: transform 0.5s;
  border: 0.5px solid #b2b2b242;
  margin: 5px;
  border-radius: 8px;
}
.category-product-main ul li:hover {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1.04);
}
.category-background-div {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  list-style: none;
}

.category-background-div span {
  position: relative;
  background: #000;
  border-radius: 27px;
  font-size: 12px;
  color: #fff;
  padding: 5px 20px;
  opacity: 0.8;
  top: 60%;
  font-weight: bold;
  /* rotate: 320deg; */
}
.category-product-main ul li img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.category-product-main ul li h5 {
  margin: 0px;
  padding: 0px;
  text-align: start;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 0px;
  letter-spacing: 0.8px;
}

.category-product-main ul li p {
  margin: 0px;
  padding: 0px 0px;
  font-size: 11px;
  font-weight: 600;
}

.category-product-main ul li span {
  /* color:grey; */
  font-size: 11px;
  font-weight: 500;
}
.category-product-main ul li h6 {
  margin: 0px;
  padding: 10px 0px 0px 0px;
  color: grey;
  font-size: 10px;
}
.category-product-main ul li button {
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.category-product-main ul li svg {
  font-size: 25px;
  margin: 20px;
  background: #fff;
  padding: 9px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
}

.category-product-main ul li svg :hover {
  height: 80px;
}

.category-product-main ul li:hover .category-on-hover-card {
  height: 20%;
}

.category-on-hover-card {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  padding: 0px 10px;
}

.category-on-hover-card button {
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 10px auto;
}

.category-on-hover-card button:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.category-on-hover-card p {
  text-align: center !important;
}
.category-on-hover-card span {
  text-align: center !important;
}

.category-page-route-heading {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin: 10px 0px 0px 8px;
}
.category-page-route-heading h2 {
  font-size: 13px;
  color: grey;
  margin: 3px 0px 0px 5px;
}
.category-filter {
  position: absolute;
  top: 70px;
  left: 8px;
  z-index: 9999;
  background: #2e2e97;
  width: 322px;
}
#btn-outline-success:hover {
  background-color: #2e2e97;
  border-color: "#2e2e97";
}

@media only screen and (max-width: 768px) {
  .category-product-main ul li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .category-product-main ul li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 576px) {
  .category-background-div {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    list-style: none;
    object-fit: cover;
  }
  .category-product-main ul li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 375px) {
  .category-filter {
    width: 280px;
  }
}
@media only screen and (max-width: 321px) {
  .category-filter {
    width: 280px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .category-product-main ul li {
    width: 24%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .category-product-main ul li {
    width: 32%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .category-product-main ul li {
    width: 31%;
  }
}
@media only screen and (min-width: 524px) and (max-width: 768px) {
  .category-product-main ul li {
    width: 48%;
  }
}
@media only screen and (min-width: 356px) and (max-width: 523px) {
  .category-product-main ul li {
    width: 47%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 356px) {
  .category-product-main ul li {
    width: 46%;
  }
}
