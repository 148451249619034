.slick-slider{
    padding: 0px 15%;
}

.slick_slider .container-fluid {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/public/img/testimonial-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slick_slider .container-fluid {
  padding: 3%;
}
/* .slick_slider .container .testimonial_main  {
    width:70% !important
} */
.slick_slider .container-fluid .testimonial_main h3 {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
.slick_slider .container-fluid .testimonial_main p {
  text-align: center;
  color: white;
  padding: 0 10%;
  font-size: 14px;
  font-weight: 100;
}

.slick_slider .FulfillmentSlider_Arrowr_right {
  display: block;
  color: black;
  position: absolute;
  bottom: 40%;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
  z-index: 99;
  font-size: 10px;
}

.slick_slider .FulfillmentSlider_Arrowr_left {
  color: black;
  position: absolute;
  z-index: 99;
  bottom: 40%;
  left: 0;
  width: 50px;
  height: 50px;
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
}


.testimonial_rating{
text-align: center;
}

.testimonial_rating .rating_star{
  color: rgba(255, 184, 0, 1);
  font-size: 22px;

}

.testimonial_main h5{
color: white;
text-align: center;
margin: 8px 0px;
}

.testimonial_main h6{
    text-align: center;
    color:  rgba(255, 255, 255, 0.5);
}


@media (max-width:576px){
  .slick-slider {
    padding: 0px;
  }
  .slick_slider .FulfillmentSlider_Arrowr_left,.slick_slider .FulfillmentSlider_Arrowr_right {
    width: 34px;
  
  }
  .slick_slider .container-fluid .testimonial_main p , .testimonial_main h6 {
    font-size: 13px;
  }
  .testimonial_main h5{

    font-size: 16px;
  }
  .slick_slider .container-fluid .testimonial_main h3{
    margin-bottom: 22px;
    font-size: ;
  }
  .slick_slider .container-fluid {
    padding: 7% 3%;
  }
}