.privacy-policy h1 {
  text-align: center;
  color: #333;
}

.privacy-policy h2 {
  margin-top: 20px;
}

.privacy-policy p {
  margin: 10px 0;
  font-size: 16px;
}

.privacy-policy a {
  color: #2e2e97;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy h6 {
  font-weight: 600;
}
