.contact-1 {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  /* border: 1px solid black; */
}
.contact-office {
  border: 1px solid #2e2e97;
  padding: 20px 40px;
  text-align: center;
  /* margin: 5px; */
  border-radius: 10px;
}

.contact-office a{
  color: rgb(101, 97, 97);
}

.contact-office svg {
  font-size: 30px;
  color: #2e2e97;
}
.contact-office h5 {
  margin-top: 10px;
  font-weight: 600;
}
.contact-office p {
  font-size: 16px;
  color: rgb(101, 97, 97);
}
/* .form-label {
  color: white;
} */

.contact-2 h5 {
  color: white;
}

.contactus-label {
  color: #ffffff !important;
}

.contact-2 {
  /* height:300px; */
  width: 600px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border: 2px solid #2e2e97;
  border-radius: 10px;
  padding: 20px;
  background-color: #2e2e97;
}
.contacts-button {
  background-color: black;
  border: 1px solid black;
  color: white;
}

@media only screen and (max-width: 576px) {
  .contact-2 {
    width: 300px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 2600px) {
  .contact-office {
    height: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .contact-office {
    height: 220px;
  }
}
