.sidebar-navbar .sidebar-navbar-nav {
  width: 100%;
  text-align: left;
  padding: 0px;
}

.sidebar-navbar .sidebar-nav-item {
  border-bottom: 1px solid #ccc;
  list-style-type: none;
  border-bottom: 0.5px solid rgb(128, 117, 117);
  padding: 20px 0px;
}

.sidebar-navbar .sidebar-nav-link {
  font-size: 18px;
  color: #000;
  margin: 0px;
}

.sidebar-navbar .sidebar-nav-link:hover {
  color: #000;
  text-decoration: none;
}

.sidebar-navbar .sidebar-navbar-toggler {
  outline: none;
  border: none;
}
