.orderheading {
  /* border: 1px solid red; */
}
.order-adders {
  /* height: 300px; */
  width: 100%;
  border: 2px;
  padding: 20px;
  margin-top: 20px;
  /* box-shadow:1px 0px 3px 0px; */
  box-shadow: 0px 0px 1px 1px #949290;
  border-radius: 5px;
}

.order-table-image {
  width: 70px;
  height: 70px;
}
.order-table-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.delivery-address-producet p {
  margin-bottom: 0px;
}
.order-adders-image img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.trackbutton-cancel {
  background-color: #2e2e97;
  border: #2e2e97;
}
.trackbutton-cancel:hover {
  background-color: #2e2e97;
  border: #2e2e97;
}
.order-table-status {
  border: 1px solid #2e2e97;
  background-color: #2e2e97;
  color: white;
  border-radius: 5px;
  height: 30px;
  padding: 0px 10px;
  cursor: pointer;
}

/* responsive   425 */
@media only screen and (max-width: 425px) {
  .order-adders {
    padding: 14px;
  }

  .trackbutton-cancel {
    background-color: #2e2e97;
    border: #2e2e97;
  }
}
