.filter-section {
  background-color: #fff;
}

.filter-section .filter-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.filter-section .filter-options {
  display: flex;
  flex-direction: column;
}

.filter-section .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-section .form-check-input {
  margin-top: 0px !important;
}

.filter-section .form-check-label {
  margin-left: 5px;
  font-size: 14px;
}

.filter-section .price-input {
  width: 80px;
  margin-right: 5px;
}
.filter-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #000; /* Border color */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background */
  color: #000; /* Text color */
  font-size: 16px; /* Font size */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  margin: 5px;
}

.filter-button:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}

.filter-icon {
  margin-right: 8px; /* Space between icon and text */
  font-size: 18px; /* Icon size */
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.offcanvas-enter {
  animation: slideIn 0.6s ease-out forwards;
}

.offcanvas-exit {
  animation: slideOut 0.6s ease-in forwards;
}

@media only screen and (max-width: 500px) {
  .filter-button {
    padding: 5px 10px;
    font-size: 14px;
  }
}
