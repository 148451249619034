.videomodel {
  height: 500px;
  width: 600px;
}
@media only screen and (max-width: 767px) {
  .videomodel {
    height: 250px;
    width: 350px;
  }
}
@media only screen and (max-width: 400px) {
  .videomodel {
    height: 200px;
    width: 250px;
  }
}
