.addreview {
  padding: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addreview-star {
  width: 100%;
  justify-content: center;
  display: flex;
}

.addreview h5 {
  font-weight: bold;
  margin-top: 20px;
}
.addreview-star svg {
  font-size: 25px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #2e2e97;
}
.addreviewImagplus {
  position: absolute;
  top: 43%;
  left: 43%;
}
.addreviewImag {
  height: 100px;
  width: 100px;
  position: relative;
}
.addreview-button {
  background-color: #2e2e97;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 9px 11px;
  transition: all 0.5s;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}
.addreview-button:hover {
  background-color: #4348a6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .addreview {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .addreviewImag {
    height: 80px;
    width: 80px;
  }
}
