.Review-image img{
    height:50px;
    width: 50px;
}
.Review h5{
    font-weight: bold;
}

.page-item a{
    background-color: #2e2e97!important;
border: none;
border-radius: 0px !important;
}
.page-item:active .page-link a{
    background-color: red !important;
    color: black !important;
    border-radius: 0px !important;
}
/* .back{
    padding: 3px;
} */
/* .page-links:active{
    background-color: rgb(230, 221, 221) !important;
    color: rgb(0, 255, 72) !important;

} */
/* .page-item:active {
    border-color: none !important;
    border-radius: none;
} */

.page-link:focus{
    box-shadow: none;
}