.new-address-form {
  background-color: white;
  border-radius: 5px;
}

.new-address-form fieldset {
  border: none;
}

.new-address-form .form-parts {
  padding: 20px;
}

.new-address-form legend {
  font-size: 1em;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #8080803b;
  margin: 0px !important;
}

.new-address-form label {
  display: block;
  margin: 10px 0 5px;
  color: grey;
}

.new-address-form input[type="text"],
[type="number"],
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-address-form input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
  /* background-color: #2e2e97;
  border-color: #2e2e97; */
}

.new-address-form button {
  background-color: #2e2e97;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.new-address-form button:hover {
  background-color: #2e2e97;
}
