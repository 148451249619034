.profile-slider-left {
  border-style: none inset none none;
  border: 1px solid lightgray;
  padding: 30px 25px;
}

.profile-slider-left-top-div {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-form-outer {
  /* border: 1px solid lightgray; */
  padding: 7px;
  border-radius: 5px;
}

.profile-slider-left-top-div img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(157, 131, 98);
  padding: 10px;
}

.profile-slider-left-top-div h4 {
  font-weight: 600;
  padding-top: 10px;
  font-size: 18px;
}

.profile-slider-left-top-div p {
  font-size: 13px;
  color: #2e2e97;
  margin: 0px;
  margin: 0px 0px 12px 0px;
}

.profile-slider-left-top-div button {
  background-color: #2e2e97;
  padding: 5px 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 14px;
  color: #ffffff;
  border-radius: 5px;
}

.profile-slider-left-Myorder {
  padding: 10px 0px;
  border-style: outset none inset none;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
}

.profile-slider-left-Myorder .download-icon {
  font-size: 13px;
  color: rgb(157, 131, 98);
}

.profile-slider-left-Myorder p {
  margin: 0px;
  font-size: 16px;
  color: #2e2e97;
}

.profile-slider-left-Myorder .greater-than {
  font-size: 11px;
  color: rgb(183, 180, 180);
  display: flex;
  justify-content: end;
  align-items: center;
}

.profile-slider-left-account-setting {
  border-style: none none inset none;
  border: 1px solid #dcdcdc;
  padding: 15px 0px;
  margin: 10px 0px;
  border-radius: 5px;
}

.profile-slider-left-account-setting .left-slider-icon {
  font-size: 13px;
  color: rgb(157, 131, 98);
}

.profile-slider-left-account-setting .account {
  font-size: 16px;
  color: #2e2e97;
  font-weight: 600;
}

.profile-slider-left-account-setting p {
  font-size: 13px;
  color: #7e7e7e;
  margin: 0px;
  line-height: 25px;
  cursor: pointer;
}
.profile-slider-left-account-setting p:hover {
  text-decoration: underline;
}
.profile-slider-left-logout {
  padding: 10px 0px;
  border-style: none none inset none;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.profile-slider-left-logout .download-icon {
  font-size: 13px;
  color: #2e2e97;
}

.profile-slider-left-frequently h4 {
  font-size: 13px;
  color: #2e2e97;
  padding: 20px 0px 0px 0px;
}

.profile-slider-left-logout p {
  margin: 0px;
  font-size: 16px;
  color: #2e2e97;
  font-weight: 600;
  cursor: pointer;
}

.profile-slider-left-frequently p {
  font-size: 12px;
  color: rgb(183, 180, 180);
}

/* ////////RIGTH SIDE//// */

/* ///////////// */

.profile-right {
  border: 1px solid lightgray;
  padding: 20px 20px 20px 50px;
}

.profile-right h5 {
  /* border-bottom: 1px solid lightgray; */
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
.profile-right svg {
  margin: 0px 0px 0px 5px;
}

.input-div-profile {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.input-div-profile input {
  padding: 20px 16px 16px 13px;
  border-radius: 2px;
  outline: none;
  border: 1px solid #e0e0e0;
  font-size: 13px;
}
.input-div-profile label {
  font-size: 14px;
  padding: 3px 0px;
  font-weight: 500;
}
.profile-right .save-detalis {
  background-color: #2e2e97;
  height: 35px;
  font-size: 16px;
  margin: 20px 0px;
}
.profile-right .change-password {
  background-color: rgb(0, 0, 0);
  height: 3 5px;
  font-size: 16px;
  margin: 20px 0px;
}
.profile-address-radio-buttons h6 {
  color: rgb(96, 95, 95);
  font-size: 14px;
  margin: 0px;
}

.profile-mobile-field h6 {
  color: rgb(96, 95, 95);
  font-size: 14px;
  margin: 0px;
}

.profile-mobile-field select {
  border-radius: 5px;
  outline: none;
  border: 1px solid grey;
  font-size: 12px;
  padding: 8px 6px;
}

.profilemenu-box {
  height: auto;
  width: 300px;

  display: none;
}
.profilemenu {
  position: relative;
}

.profile-add-address .address-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}
.profile-add-address h2 {
  font-size: 20px;
  font-weight: 600;
}

.profile-add-address h5 {
  font-size: 15px;
  font-weight: 600;
}

.profile-add-address .address-card p {
  margin: 0;
  color: #666;
  font-size: 13px;
  line-height: 1.5;
}
.profile-add-address .add-address-btn {
  background-color: #2e2e97;
  height: 35px;
  color: white;
}
.phone-number {
  font-size: 0.9rem;
  color: #777;
}

@media (min-width: 768px) and (max-width: 900px) {
  .profile-slider-left-account-setting p {
    font-size: 11px;
    color: #7e7e7e;
    margin: 0px;
    line-height: 25px;
    cursor: pointer;
    margin-left: 21px;
  }
}

/* responsive */

@media only screen and (min-width: 0px) and (max-width: 991.5px) {
  .profile-slider-left {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  /* .profilemenu:hover +  .profilemenu-box{
        display: block;
    } */
  .profile-slider-left-top-div {
    justify-content: center;
  }
  #prfolie-right-5 {
    width: 100%;
  }
  .profile-slider-left-top-div button {
    margin-left: 20px;
  }
  .profile-slider-left-top-div h4 {
    display: flex;
    justify-content: center;
  }

  .profile-slider-left-top-div img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }
  .profile-right {
    border: 1px solid lightgray;
    padding: 18px 20px 20px 19px;
  }
  .profilemenu-box {
    height: 100vh;
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #2e2e97;
    color: white;
    z-index: 9999;
    position: absolute;
    top: 0px;
    /* bottom: 30px; */
    font-size: 16px;
    transition: 2s;
    transform: translate(-5%, 0px);

    left: auto;
  }
  .profilemenu:active.profilemenu-box {
    transform: translate(-100%, 0px);
  }
  .profilemenu-heading {
    margin: 20px 0px;
    cursor: pointer;
  }
  .profilemenu-close {
    font-size: 40px;
    border-bottom: 1px solid rgb(241, 238, 238);
  }
  .profile-slider-left-top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }
}
@media only screen and (max-width: 350px) {
  order-adders p {
    font-size: 11px;
  }
}
