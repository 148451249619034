.todaypopularsale{
    padding: 50px 0px;
    width:100%
}

.heading-todaypopularsale p{
    color: grey;
}
.heading-todaypopularsale h2{
    font-weight: 600;
}

.todaypopularsale-line{
    border: 1px solid black;
    height: 3px;
    width: 20%;
    display: inline-block;
    background-color: black;
}
.todaypopularsale-dot{
    border: 1px solid  #2e2e97;
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color:  #2e2e97;
    border-radius: 50%;
    position: relative;
    left: 10%;
    top: 3%;
}


.todaypopularsale-view-all h6{
    color: #2e2e97;
   font-weight: 700;
   font-size: 18px;
}
.todaypopularsale-sale-text{
    color: #2e2e97;
}

.timer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.owl-carousel .owl-item img{
    border-radius: 50% !important;
    height: 200px;
    width: 200px;
    margin: auto;
}
.todaypopularsale-main {
    text-align: center;
}

.todaypopularsale-main h5{
    margin: 22px 0px 0px 0px;
    font-weight: 600;
}
.todaypopularsale-main span{
    font-size: 14px;
    color: grey;
}

.timer_heading h3{
    font-weight: bold;
}


@media only screen and (max-width:1024px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 170px;
        width: 170px;
        margin: auto;
    }
    .timer{
        width: 100%;
    } 
}
@media only screen and (max-width:768px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 100px;
        width: 100px;
        margin: auto;
    }
    .timer{
        width: 100%;
    } 
}
@media only screen and (max-width:525px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 70px;
        width: 70px;
        margin: auto;
    }
    .todaypopularsale-view-all h5{
        margin-top: 10px;
        font-size: 14px;
    }
    .todaypopularsale-main h5{
        font-size: 14px;
    }
    
}
@media only screen and (max-width:375px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
        margin: auto;
    }
    
}