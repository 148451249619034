.discount-price {
  color: grey;
  font-size: 14px;
  text-decoration: line-through;
}

.similierproduct-main {
  width: 100%;
}

.similierproduct-main ul {
  padding: 0px;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  /* padding: 20px 0px; */
}
.similierproduct-main ul li {
  cursor: pointer;
  list-style: none;
  padding: 0px 0px;
  transition: transform 0.3s ease;
  width: 19%;
  border: 0.5px solid #b2b2b242;
  margin: 0px 5px;
  border-radius: 8px;
}
.similierproduct-main ul li:hover {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1.04);
}
.similierproduct-background-div {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  list-style: none;
}

.similierproduct-background-div span {
  position: relative;
  background: #000;
  border-radius: 27px;
  font-size: 12px;
  color: #fff;
  padding: 5px 20px;
  opacity: 0.8;
  top: 60%;
  font-weight: bold;
  /* rotate: 320deg; */
}
.similierproduct-main ul li img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.similierproduct-main ul li h5 {
  margin: 0px;
  padding: 0px;
  text-align: start;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 0px;
  letter-spacing: 0.8px;
}

.similierproduct-main ul li p {
  margin: 0px;
  padding: 0px 0px;
  font-size: 11px;
  font-weight: 600;
}

.similierproduct-main ul li span {
  /* color:grey; */
  font-size: 11px;
  font-weight: 500;
}
.similierproduct-main ul li h6 {
  margin: 0px;
  padding: 10px 0px 0px 0px;
  color: grey;
  font-size: 10px;
}
.similierproduct-main ul li button {
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.similierproduct-main ul li svg {
  font-size: 25px;
  margin: 20px;
}


@media only screen and (max-width: 525px) {
  .heart_icon_wishlist {
    position: absolute;
    z-index: 9999;
    right: 20px !important;
    top: 20px;
    background-color: aliceblue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .product-right-side-information-polyester {
    color: #2e2e97;
    font-size: 13px !important;
  }
  .product-right-side-information {
    font-size: 13px;
  }
  .product-right-side-information-price {
    font-size: 13px !important;
    font-weight: 600;
  }
  .product-right-side-information-off {
    font-size: 14px !important;
  }
  .product-right-side-information-taxs {
    color: #2e2e97;
    font-size: 11px !important;
  }
  .select-size {
    margin-top: 10px !important;
  }
  .similierproduct-main {
    margin-top: 30px !important;
  }
  .products-buttons button {
    font-size: 13px !important;
    padding: 10px 20px !important;
  }
  .delivery-option {
    font-size: 13px !important;
  }
  .delivery-option ul li p {
    font-size: 11px !important;
  }
  .delivery-option ul li svg {
    font-size: 11px !important;
  }
  .product-information {
    font-size: 12px !important;
  }
  .product-rating-1 span {
    font-size: 20px;
  }
  .product-rating-1 p {
    font-size: 13px;
  }
  .product-rating-1 h6 {
    font-size: 14px !important;
  }
  .product-rating-2 {
    width: 65%;
    font-size: 13px;
  }
  .product-rating-2 ul li span {
    font-size: 12px;
    margin: 0px 5px;
  }
  .similierproduct-main {
    font-size: 20px !important ;
    font-weight: 600;
  }
}
@media only screen and (max-width: 375px) {
  .about-product p {
    font-size: 10px !important;
  }
  .about-product h6 {
    font-size: 12px !important;
  }
}

.css-m0or9c-MuiBadge-badge {
  background-color: #2e2e97 !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .similierproduct-main ul li {
    width: 24%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .similierproduct-main ul li {
    width: 32%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .similierproduct-main ul li {
    width: 31%;
  }
}
@media only screen and (min-width: 379px) and (max-width: 768px) {
  .similierproduct-main ul li {
    width: 47%;
  }
}
@media only screen and (min-width: 0px) and (max-width:500px) {
  .similierproduct-main ul li {
    width: 46%;
  }
}
@media only screen and (min-width: 0px) and (max-width:300px) {
  .similierproduct-main ul li {
    width: 45%;
  }
}
