.heading-topsale p {
  color: grey;
}
.heading-topsale h2 {
  font-weight: 600;
}
.heading-topsale span {
  color: #2e2e97;
}

.topsale-tab-list ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 30px 0px;
  gap: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  overflow-x: scroll;
}

.topsale-tab-list ul::-webkit-scrollbar {
  display: none;
}

.parent-category-bar {
  align-items: center;
  background: #0000001a;
  border-radius: 1px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  list-style: none;
  padding: 10px 20px;
}

.parent-category-bar span {
  width: max-content;
}

.select-topsale-item {
  align-items: center;
  background: #2e2e97 !important;
  background: #0000001a;
  border-radius: 1px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  list-style: none;
  padding: 10px 20px;
}

.select-topsale-item span {
  width: max-content;
}

.topsale-line {
  border: 1px solid black;
  height: 3px;
  width: 20%;
  display: inline-block;
  background-color: black;
}

.topsale-dot {
  border: 1px solid #2e2e97;
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #2e2e97;
  border-radius: 50%;
  position: relative;
  left: 10%;
  top: 3%;
}

@media only screen and (min-width: 260px) and (max-width: 500px) {
  .parent-category-bar {
    padding: 6px 10px;
  }
  .topsale-tab-list ul {
    gap: 10px;
  }
}
