/* .bannerpage {
  background-image: url(/public/img/bannercheck.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 20px;
} */

.banner-1 span {
  color: #2e2e97;
}

.banner-1 h1 {
  line-height: 60px;
  font-weight: 700;
  font-size: 48px;
}

.banner-1 p {
  margin: 0px;
  padding: 0px;
  color: black;
}

.banner-1 button {
  background-color: #2e2e97;

  padding: 10px 25px;
  outline: none;
  border: none;
  color: #fff;
  margin: 30px 0px 0px 0px;
}

/* media queriy */
@media only screen and (max-width: 1024px) {
  .bannerpage {
    padding: 70px 20px;
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .bannerpage {
    padding: 30px 10px;
    height: auto;
    width: 100%;
    background-image: url(/public/img/banner.png);
  }
  .banner-1 h1 {
    color: #2e2e97;
    font-size: 20px;
  }
  .banner-1 h1 {
    line-height: 40px;
    font-size: 25px;
  }
  .banner-1 p {
    font-size: 15px;
  }
  .banner-1 button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 525px) {
  .bannerpage {
    padding: 30px 10px;
    height: auto;
    background-position: 50%;
    background-image: url(/public/img/banner.png);
  }
  #loerm {
    width: 60%;
  }
  .banner-1 p {
    font-size: 10px;
  }
  .banner-1 h2 {
    font-size: 15px;
    line-height: 20px;
  }
  .banner-1 h4 {
    font-size: 15px;
  }
  .banner-1 button {
    font-size: 15px;
    padding: 6px 20px;
  }
}
@media only screen and (max-width: 380px) {
  .bannerpage {
    background-position: 70%;
  }
  .banner-1 h4 {
    font-size: 11px;
  }
}
.home-mobile-banner {
  display: none;
}

@media only screen and (max-width: 500px) {
  .home-desktop-banner {
    display: none !important;
  }
  .home-mobile-banner {
    display: block !important;
  }
}
