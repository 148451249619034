.parentcategory-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  cursor: pointer;
  transition: transform 0.5s ease;
  margin-right: 15px;
}

.parent-category-img-div {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 1px solid rgba(87, 89, 203, 1);
  padding: 5px;
}

.parent-category-row {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.parent-category-row::-webkit-scrollbar {
  display: none;
}

.parent-category-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.parentcategory-main:hover .parent-category-img {
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(87, 89, 203, 1);
}

.parentcategory-main:hover p::after {
  left: 0;
}

.parentcategory-main:hover + p {
  transform: translateY(-20px);
}

.circle_row h3 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
}

.parentcategory-main p {
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  padding: 15px 0px 0px 0px;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease, color 0.5s ease;
}

.parentcategory-main p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background-color: rgba(87, 89, 203, 1);
  transition: left 0.9s ease;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .parent-category-img-div {
    width: 200px;
    height: 200px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .parent-category-img-div {
    width: 170px;
    height: 170px;
  }
  .parentcategory-main p {
    font-size: 18px;
  }
}
@media screen and (min-width: 260px) and (max-width: 768px) {
  .parent-category-img-div {
    width: 140px;
    height: 140px;
  }
  .parentcategory-main p {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .parent-category-row {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .circle_row h3 {
    margin-bottom: 6px !important;
  }
}
