.navbar-logos img {
  height: 50px;
  cursor: pointer;
}

.navbar-margin {
  padding-top: 12px;
  padding-bottom: 12px;
}

.navbar-menu-items ul {
  margin: 0px;
  padding: 0px;
  display: flex;
}
.navbar-menu-items ul li {
  padding: 10px 20px;
  list-style: none;
  cursor: pointer;
  color: #232627;
}
.navbar-menu-items ul li:hover {
  background: #2e2e97;
  color: #fff;
  border-radius: 2px;
}
.navbar-last-items {
  display: flex;
  justify-content: end;
  width: 100%;
}
.navbar-search-field {
  width: 100%;
}
.navbar-search-field input {
  background: #eeeeee;
  border: none;
  outline: none;
  padding: 8px 0px 8px 20px;
  width: 100%;
  color: rgba(129, 129, 129, 1);
  border-radius: 6px;
}
.navbar-search-field svg {
  position: absolute;
  font-size: 18px;
  left: 92%;
  color: rgba(129, 129, 129, 1);
}
.navbar-last-item-2 p {
  margin: 0px;
  font-size: 14px;
}
.navbar-last-item-2 {
  text-align: center;
  cursor: pointer;
}

.navbar-last-item-2 svg {
  color: black;
  font-size: 20px;
}

.search-bar-result p {
  text-align: left;
  font-size: 13px;
  border-bottom: 1px solid #ddd !important;
  margin-bottom: 0px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 2.5;
}

.searching-div {
  padding: 10px;
}

.searchDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.searchItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.searchItem:last-child {
  border-bottom: none;
}

.searchItem:hover {
  background-color: #f5f5f5;
}

.searching-div:hover {
  background-color: #2e2e97;
  color: white;
}

.navbar-menu-items ul .li_one:hover {
  color: white;
}

.search-bar-result {
  position: absolute;
  background: white;
  /* height: 250px; */
  overflow-y: scroll;
  box-shadow: 0px 2px 6px 1px#2e2e978c;
  scrollbar-color: transparent transparent;
  /* padding: 10px; */
  width: 100%;
  top: 40px;
}
.search-bar-result::-webkit-scrollbar {
  display: none;
}
/* .navbar-search-field-1{

} */
.toggelmenuheading {
  width: 100%;
  position: absolute;
  transition: 2s;
  /* top:85px; */
  /* width: 96.9%; */
  top: 0px;
  /* right: 36px; */
  /* -webkit-transition: all 0.5s ease-out; */
  /* transform: translate(20px, 20px); */
  background-color: #2e2e97;
  color: white;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-left: 20px;

  /* padding-left:20px; */
  /* transform: translate(2%, 0px) !important; */

  right: auto !important;

  z-index: 99999;
}
.navbar-toggle-section:active .toggelmenuheading {
  transform: translate(100%, 0px) !important;
}
.catDropdown {
  position: relative;
}

.catDropdown-content {
  position: absolute;
  top: 40.1px;
  left: 0%;
  width: 200px;
  max-height: 400px;
  background: #fff;
  overflow-y: scroll;
  z-index: 99999;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: 0.1s;
  border: 0.5px solid #dcdcdc;
}
.catDropdown-content p:hover {
  color: #fff !important;
}
.catDropdown-content hr:last-of-type {
  display: none;
}
.catDropdown-content::-webkit-scrollbar {
  display: none;
}
.catDropdownhover {
  padding: 10px;
}
.catDropdownhover:hover {
  background-color: #2e2e97;
  padding: 10px;
}

.catDropdown-content-small {
  height: 130px;
  overflow-y: scroll;
}
.catDropdown-content-small::-webkit-scrollbar {
  display: none;
}
.catDropdown-content-small:hover {
  background-color: #2e2e97;
}
/* responsive 1024px */
@media only screen and (max-width: 1024px) {
  .navbar-menu-items ul li {
    font-size: 15px;
    padding: 10px 10px;
  }

  .navbar-search-field input {
    font-size: 15px;
  }
}
/* responsive 768 */
@media only screen and (max-width: 768px) {
  .navbar-toggle-section {
    width: 16% !important;
  }
  .navbar-margin {
    padding-top: 6px;
    padding-bottom: 4px;
  }
}
/* responsive  525 */
@media only screen and (max-width: 525px) {
  /* .navbar-search-section{
        display: none;
    } */
  .catDropdown-content-small {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1700px) {
  .navbar-search-field svg {
    left: 91%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .navbar-search-field svg {
    left: 90%;
  }
}

/* @media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .navbar-menu-items ul li {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1284px) and (max-width: 1400px) {
  .navbar-menu-items ul li {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1284px) {
  .navbar-menu-items ul li {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1180px) {
  .navbar-menu-items ul li {
    font-size: 11px;
  }
} */
