.footer {
  background-color: rgba(4, 4, 27, 0.8);
  padding: 40px 40px;
}
.footer-bottom {
  background-color: rgba(4, 4, 27, 0.8);
  border-top: 2px solid rgba(255, 255, 255, 0.274);
  padding: 15px 40px;
}
.footer-bottom span {
  cursor: pointer;
}

.subscribe-now-input {
  font-size: 14px;
}

.footer-first-section p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}
.payment-image {
  width: 80px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-image img {
  width: 60px;
}
.footer-first-section ul {
  padding: 0px;
}
.footer-first-section ul li {
  list-style: none;
  color: #fff;
  cursor: pointer;
  padding: 3px 0px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}
.footer-first-section ul li:hover {
  text-decoration: underline;
}
.footer-first-section ul li:first-child {
  list-style: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  padding: 0px 0px 20px 0px;
}

.footer-second-section ul {
  padding: 0px;
}

.footer-second-section ul li {
  list-style: none;
  color: #fff;
  cursor: pointer;
  padding: 3px 0px;
  font-size: 20px;
}
.footer-second-section h3 {
  list-style: none;
  color: white;
  font-weight: 500;
  font-size: 20px;
  padding: 0px 0px 20px 0px;
}

.footer-second-section p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  margin-top: 20px;
}

.store-img img {
  margin: 0px 5px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.footer-social-icon svg {
  margin: 0px 15px 0px 0px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: white;
}

.social_main h3 {
  color: white;
  font-size: 20px;
}

.payment_main h3 {
  color: white;
  font-size: 20px;
  margin-bottom: 10px;
}

.payment_icon {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.privacy_row {
  display: flex;
  justify-content: space-between;
}

.privacy_row p {
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  font-weight: 200;
  margin: 0px;
}

@media only screen and (max-width: 1024px) {
  /* .footer-second-section ul li:first-child{
  font-size: 19px;
}
.footer-first-section ul li:first-child {
    font-size: 17px;
} */
}
@media (min-width: 767px) and (max-width: 800px) {
  .footer-second-section ul li:first-child {
    font-size: 20px;
  }
  .footer-first-section ul li:first-child {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .store-img img {
    margin: 0px 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 5px;
  }
  .social_row {
    gap: 10px;
  }
  .privacy_row p {
    font-size: 13px;
  }
  .privacy_main {
    padding: 0px;
  }
}

@media only screen and (max-width: 525px) {
  .store-img img {
    margin: 0px 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 5px;
  }
  .payment_icon {
    justify-content: start;
  }
  .copyrite {
    padding: 0px;
  }
  .privacy {
    justify-content: space-between !important;
  }
  .footer-bottom {
    padding: 9px 14px;
  }
  .privacy_row {
    gap: 8px;
  }
  .footer-first-section ul li {
    font-size: 14px;
  }
  .privacy_row p {
    text-align: center;
  }
}

.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}
.newsletter .content h2 {
  color: #243c4f;
  margin-bottom: 40px;
}
.newsletter .content .form-control {
  height: 40px;
  border-color: #ffffff;
  border-radius: 0.5;
}
.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}
.footer_news_latter_btn {
  border-radius: 1;
  background: #2e2e97;
  color: #fff;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 0px 10px;
}

@media (max-width: 576px) {
  .footer {
    padding: 20px 1px;
  }
  .footer-second-section h3 {
    font-size: 17px;
  }
  .footer-first-section ul li:first-child {
    font-size: 17px;
  }
  .payment_icon {
    display: flex;
    gap: 11px;
  }
  .social_main h3 {
    font-size: 17px;
  }
  .payment_main h3 {
    font-size: 17px;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .subscribe-now-input {
      font-size: 11px;
  }
}
