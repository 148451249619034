.marquee {
  width: 100%; /* Adjust to fit your container */
  overflow: hidden;
  position: relative;
  background-color: #000000;
}
.marquee span {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%; /* Start position off-screen */
  animation: marquee 50s linear infinite;
  color: #fff;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.topbar {
  background: #000000;
  padding: 5px 0px;
}
.topbar-main-1 {
  display: flex;
  justify-content: end;
  color: #fff;
  align-items: center;
  gap: 10px;
}

.topbar-main-1 p {
  margin: 0px;
  font-size: 15px;
}

.topbar_col {
  display: flex;
  justify-content: space-evenly;
}

.icn {
  font-size: 14px;
}

@media (max-width: 576px) {
  .topbar_col {
    justify-content: space-between;
  }
  .topbar-main-1 p {
    font-size: 12px;
  }
  .topbar-main-1 {
    gap: 4px;
  }
  .search-bar-result {
    padding: 0px !important;
  }
}
