.checkout {
  min-height: 50vh;
}

.checkout .accordion-header {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 1rem;
  cursor: pointer;
}

.checkout .accordion-body {
  padding: 1rem;
  border: 1px solid #ddd;
  border-top: none;
}

.checkout .checkout-section {
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.checkout .checkout-header {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-weight: bold;
}

.checkout .btn-primary-custom {
  background-color: #ff5722;
  border: none;
  padding: 0.5rem 2rem;
  color: white;
  font-weight: bold;
}

.checkout .order-summary {
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #f9f9f9;
  position: sticky;
  top: 20px;
}

.checkout .order-summary h5 {
  font-weight: bold;
}

.checkout .order-summary .price-details {
  margin-top: 1rem;
}

.checkout .price-details p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.checkout .btn.focus,
.btn:focus {
  box-shadow: none;
}

.checkout .card {
  margin-bottom: 10px;
}

.checkout .card-header {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.checkout .btn-link {
  color: #878787;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.address-right-iteam div span {
  font-size: 14px;
}

.adreress-left {
  /* border: 1px solid #2e2e97; */
  border-radius: 5px;
  width: 100%;
  max-height: 400px;
  padding: 0px 20px;
  overflow: scroll;
}

.adreress-left::-webkit-scrollbar {
  display: none;
}

.adreress-right::-webkit-scrollbar {
  display: none;
}

.adreress-left h6 {
  font-size: 20px;
  font-weight: bold;
}
.adreress-right {
  /* border: 1px solid #2e2e97; */
  width: 100%;
  max-height: 400px;
  padding: 20px;
  border-radius: 5px;
  overflow: scroll;
}
.address-left-side {
  font-size: 15px;
  color: black;

  align-items: center;
}
.address-left-border-bottom {
  /* border-bottom: 1px solid#2e2e97 */
}
.addres-left-heading {
  font-size: 16px !important;
  font-weight: 700;
}
.address-left-side p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.address-left-image {
  height: 150px;
  width: 100%;
}
.address-left-image img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.checkoutquntity {
  width: 80px;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 5px;

  color: black;
}
.checkoutRemove {
  font-size: 16px;
  color: red;
  font-weight: 400;
  cursor: pointer;
}
.checkOut-bottom-box {
  /* height: 400px; */
  border: 1px solid #2e2e97;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}
.checkOut-bottom-box h6 {
  font-size: 20px;
  font-weight: bold;
}
.checkout-iteam {
  align-items: center;
  margin: 15px 0px;
}
.checkout-iteam p {
  margin-bottom: 0px;
}
.cheachout-heading {
  font-size: 16px;
  font-weight: 700;
}

.checkout-iteam-total {
  border-top: 1px solid #2e2e97;
}

.adreress-right-heading {
  margin-bottom: 5px;
}

.adreress-right-heading h6 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.right-address-border {
  /* border: 1px solid#2e2e97; */
  margin-top: 5px;
  padding: 10px 0px;
  border-radius: 5px;
}
.formcheckinput-right {
  border: 2px solid #2e2e97;
}

.address-right-item span {
  font-size: 14px;
}

.address-right-item button {
  font-size: 14px;
}

.adreress-right-heading button {
  font-size: 14px;
}

.right-button-address {
  background-color: #2e2e97;
  border: 1px solid #2e2e97;
  height: 35px;
}
.paymant-box {
  /* margin: 10px; */
  padding: 0px 20px;
  /* border: 1px solid #2e2e97; */
  border-radius: 5px;
  width: 96%;
  height: auto;
}
.paymant-box-headding h6 {
  font-size: 20px;
  font-weight: bold;
}
/* .pay-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */
.pay-button button {
  background-color: #2e2e97;
  border: 1px solid #2e2e97;
  width: 200px;
  height: 35px;
}
.checkouthr {
  color: #2e2e97;
  opacity: 1;
  margin-top: 0px;
}

.selectedAddress {
  margin-left: 27px;
}

.selectedAddress span {
  font-size: 14px;
}

/* responsive   768 px*/
@media (min-width: 550px) and (max-width: 850px) {
  .adreress-right-heading h6 {
    font-size: 15px;
    font-weight: bold;
  }
  .right-button-address {
    background-color: #2e2e97;
    border: 1px solid #2e2e97;
    font-size: 13px;
  }
  .adreress-left h6 {
    font-size: 16px;
    font-weight: bold;
  }
  .addres-left-heading {
    font-size: 14px !important;
  }
  .address-left-side p {
    margin-bottom: 0px !important;
    font-size: 13px;
  }

  .address-right-iteam {
    margin-left: 1rem !important;
    font-size: 14px;
  }

  .checkOut-bottom-box h6 {
    font-size: 16px;
    font-weight: bold;
  }

  .paymant-box-headding h6 {
    font-size: 16px;
    font-weight: bold;
  }
  .checkout-iteam p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .formcheck-paytam {
    font-size: 14px !important;
  }
  .paymant-box {
    width: 94%;
  }
}

/* responsive 500 */
@media only screen and (max-width: 530px) {
  .adreress-right-heading h6 {
    font-size: 15px;
    font-weight: bold;
  }
  .right-button-address {
    background-color: #2e2e97;
    border: 1px solid #2e2e97;
    font-size: 13px;
  }
  .adreress-left h6 {
    font-size: 16px;
    font-weight: bold;
  }
  .addres-left-heading {
    font-size: 14px !important;
  }
  .address-left-side p {
    margin-bottom: 0px !important;
    font-size: 13px;
  }

  .address-right-iteam {
    margin-left: 1rem !important;
    font-size: 14px;
  }
  .checkOut-bottom-box h6 {
    font-size: 16px;
    font-weight: bold;
  }

  .paymant-box-headding h6 {
    font-size: 16px;
    font-weight: bold;
  }
  .checkout-iteam p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .formcheck-paytam {
    font-size: 14px !important;
  }
  /* #address-left-border {
    width: 100%;
  } */
  #address-left-border-1 {
    /* width: 100%; */
    /* margin-top: 10px; */
  }
  .paymant-box {
    width: 95%;
  }
}
/* responsive 500 */
@media only screen and (max-width: 375px) {
  .adreress-right-heading h6 {
    font-size: 13px;
    font-weight: bold;
  }
  .right-button-address {
    background-color: #2e2e97;
    border: 1px solid #2e2e97;
    font-size: 12px;
  }
  .adreress-left h6 {
    font-size: 13px;
    font-weight: bold;
  }
  .addres-left-heading {
    font-size: 12px !important;
  }
  .address-left-side p {
    margin-bottom: 0px !important;
    font-size: 12px;
  }

  .address-right-iteam {
    margin-left: 1rem !important;
    font-size: 13px;
  }
  .checkOut-bottom-box h6 {
    font-size: 13px;
    font-weight: bold;
  }
  .paymant-box {
    width: 94%;
  }
  .paymant-box-headding h6 {
    font-size: 13px;
    font-weight: bold;
  }
  .checkout-iteam p {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .formcheck-paytam {
    font-size: 12px !important;
  }

  .checkout-iteam-total {
    border-top: 1px solid #2e2e97;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2600px) {
  .address-left-image img {
    height: 150px;
    width: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .address-left-image img {
    height: 125px;
    width: 125px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .address-left-image img {
    height: 110px;
    width: 100px;
  }
}
@media screen and (max-width: 767px) {
  .address-left-image img {
    height: 150px;
    width: 140px;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
  .address-left-image img {
    height: 110px;
    width: 100px;
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .address-left-image img {
    height: 100px;
    width: 80px;
  }
}
@media screen and (min-width: 200px) and (max-width: 350px) {
  .address-left-image img {
    height: 80px;
    width: 70px;
  }
  .checkout .btn-link {
    font-size: 12px;
  }
}
