.cardicon_main1 {
  display: flex;
  gap: 18px;
  padding: 10px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.cardicon_main2 {
  display: flex;
  gap: 18px;
  padding: 10px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.cardicon_main3 {
  display: flex;
  gap: 18px;
  padding: 10px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.cardicon_main4 {
  display: flex;
  gap: 18px;
  padding: 10px 30px;
}

.cardicon_text h5 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.cardicon_text p {
  font-size: 13px;
  color: rgba(95, 108, 114, 1);
  margin: 0px;
}

.iconcard_section {
  background-color: rgb(228, 241, 255);
  padding: 35px;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .cardicon_main2 {
    border-right: none;
  }
}

@media (max-width: 992px) {
  .cardicon_main1 {
    border-right: none;
  }
  .cardicon_main3 {
    border-right: none;
  }
}

@media (max-width: 576px) {
  .iconcard_section {
    padding: 10px;
  }
  .cardicon_main {
    border: none;
  }
  .iconcard_section .row {
    gap: 15px;
  }
}
