.main-cart .second-cart p {
  font-size: 12px;
  margin: 1px;
}

.main-cart .second-cart p span {
  color: #2e2e97;
}

.main-cart .header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  background-color: #2e2e97;
  height: 50px;
}

.main-cart .header h5 {
  font-size: 16px;
  margin: 0px;
}

.main-cart.men-image {
  width: 100%;
}

.main-cart .left-men-image {
  width: 100%;
}

/* .main-cart .left-men-image-text {
  width: 65%;
} */

/* .main-cart .left-men-image img {
  width: 100%;
} */

.main-cart .men-kurta {
  font-size: 17px;
}

.main-cart .Lorem {
  font-size: 12px;
}

.main-cart .center-number {
  background-color: #2e2e97;
  color: white;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
  margin-right: 20px;
}

.main-cart .center-number p {
  font-size: 17px;
}

.main-cart .center-number i {
  color: rgb(83, 83, 83);
}

.main-cart .free {
  color: grey;
  font-size: 18px;
}

.main-cart .return-btn {
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  border: 1px solid #2e2e97;
  outline: none;
  background-color: #2e2e97;
  font-weight: 500;
  padding: 5px 50px;
  color: white;
}

.main-cart .update-btn {
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  border: 2px solid #b7a89b;
  outline: none;
  background-color: white;
  font-weight: 500;
  padding: 5px 50px;
}

.main-cart .cupon-btn {
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  border: 1px solid #2e2e97;
  outline: none;
  background-color: white;
  font-weight: 500;
  padding: 5px 20px;
  text-align: center;
}

.main-cart .apply-btn {
  border-radius: 8px;
  font-size: 14px;
  height: 35px;
  width: 33%;
  border: 2px solid #2e2e97;
  outline: none;
  background-color: #2e2e97;
  color: white;
}

.main-cart .bottom-card {
  border: 2px solid #2e2e97;
  border-radius: 7px;
  padding: 10px 30px;
}

.main-cart .bottom-card h4 {
  font-weight: bold;
}
.main-cart .bottom-card .sub-total {
  font-weight: bold;
  color: rgb(123, 122, 122);
  border-bottom: 2px solid lightgray;
  font-size: 16px;
}
.Emtaycart {
  height: 150px;
  width: 100%;
  border: 1px solid #2e2e97;
}
.Emtaycart h1 {
  color: #2e2e97;
  font-size: 20px;
}
.coupanadd {
  border: 1px solid #f1ccaa;
  height: 80px;
  width: 400px;
  background-color: #2e2e97;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
}
.coupanadd h6 {
  margin-top: 3px;
  font-weight: bold;
}
.coupanadd p {
  font-size: 16px;
}
.main-cart .Total {
  font-weight: 500;
}
.main-cart .process {
  outline: none;
  border: none;
  background-color: #2e2e97;
  color: white;
  font-size: 14px;
  padding: 8px;
  border-radius: 6px;
  height: 35px;
}

.delete_cart svg {
  font-size: 26px;
  cursor: pointer;
}
.remove-coupun-button {
  height: 36px;
  padding: 5px;
  border: 1px solid red;
  border-radius: 10px;
  font-size: 15px;
  width: 170px;
}

.left-men-image-text p{
  font-weight: 700;
}

@media only screen and (max-width: 800px) {
  .main-cart .Lorem {
    font-size: 9px;
  }

  .main-cart .men-kurta {
    font-size: 14px;
    margin: 2px;
  }

  .main-cart .₹ {
    font-size: 14px;
  }
  .main-cart .center-number {
    width: 100px;
  }

  .main-cart .center-number i {
    font-size: 13px;
  }

  .main-cart .center-number p {
    font-size: 13px;
  }

  .main-cart .free {
    font-size: 16px;
  }

  /* .main-cart .return-btn {
    font-size: 14px;
    height: 35px;
    width: 20%;
  } */

  .main-cart .update-btn {
    font-size: 14px;
    height: 35px;
    width: 15%;
  }

  .main-cart .cupon-btn {
    font-size: 14px;
    height: 35px;
    width: 58%;
    border: 2px solid #2e2e97;
  }

  .main-cart .apply-btn {
    font-size: 13px;
    height: 35px;
    width: 36%;
  }

  .main-cart .bottom-card h4 {
    font-size: 18px;
  }

  .main-cart .bottom-card .sub-total {
    font-size: 14px;
  }

  .main-cart .Total {
    font-size: 13px;
  }

  .main-cart .process {
    font-size: 14px;
    padding: 5px;
  }
  /* .main-cart .return-btn {
    width: 25%;
    font-size: 13px !important;
    padding: 13px 5px;
  } */
  .main-cart .update-btn {
    width: 25%;
    font-size: 13px;
    padding: 13px 5px;
  }
  /* .main-cart .left-men-image img {
    width: 100%;
    height: 60px;
  } */
  .main-cart .header {
    margin-left: 1px;
    /* margin-right: 1px; */
    width: 100%;
  }
  .main-cart .apply-btn {
    width: 50%;
  }
  .main-cart .process {
    padding: 5px;
    font-size: 12px;
  }
  .home-card {
    font-size: 17px !important;
  }
  .pointer {
    cursor: pointer;
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .main-cart .header {
    display: none;
  }
  /* .main-cart .left-men-image img {
    width: 100%;
    height: 100%;
  } */
  .main-cart .left-men-image-text {
    margin-top: 20px;
    width: 100%;
  }
  .main-cart .men-kurta {
    font-size: 16px;
  }
  .main-cart .Lorem {
    font-size: 12px;
  }
  .main-cart .center-number {
    width: 100px;
  }
  .main-cart .bottom-card {
    margin-top: 15px;
  }
  .right-center {
    margin-top: 10px;
  }
  .right {
    margin: 10px 0px;
  }
  #handlecart {
    margin-left: 13px;
  }
  .center {
    padding-left: 0px;
  }
  .right-center {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .main-cart .second-cart p {
    font-size: 8px;
  }
  .second-cart .home-card {
    font-size: 17px;
  }

  .main-cart .header {
    height: 35px;
  }

  .main-cart .header h5 {
    font-size: 10px;
  }

  .main-cart .left-men-image {
    width: 100%;
  }

  /* .main-cart .return-btn {
    font-size: 10px;
    height: 25px;
    width: 25%;
  } */

  .main-cart .update-btn {
    font-size: 10px;
    height: 25px;
    width: 22%;
  }

  .main-cart .cupon-btn {
    font-size: 10px;
    height: 25px;
    width: 30%;
    padding-left: 10px;
  }

  .main-cart .apply-btn {
    font-size: 10px;
    height: 25px;
    width: 22%;
  }

  .left {
    width: 100%;
  }

  .main-cart .Lorem {
    font-size: 14px;
  }

  .main-cart .center-number p {
    font-size: 10px;
  }

  .main-cart .second-card h5 {
    font-size: 16px;
  }
  /* .main-cart .return-btn {
    width: 30%;
    font-size: 13px !important;
    padding: 13px 5px;
  } */
  .main-cart .update-btn {
    width: 30%;
    font-size: 13px;
    padding: 13px 5px;
  }
  /* .main-cart .left-men-image img {
    width: 100%;
    height: 100%;
  } */
  .main-cart .header {
    margin-left: 1px;

    width: 100%;
  }
  .main-cart .process {
    padding: 5px;
    font-size: 10px;
  }
  .main-cart .bottom-card {
    margin: 20px 0px;
    margin-left: 6px;
    width: 97%;
  }
  .main-cart .header {
    display: none;
  }
}

.pointer {
  cursor: pointer;
  font-size: 13px;
}

.process-to-checkout-btn {
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  border: 1px solid #2e2e97;
  outline: none;
  background-color: #2e2e97;
  font-weight: 500;
  padding: 8px 50px;
  color: white;
}

.shadow-mobile {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin: 10px 0px;
  padding: 10px 0px;
}

.shadow-mobile p {
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 425px) {
  .main-cart .Lorem {
    font-size: 6px;
  }

  .main-cart .men-kurta {
    font-size: 9px;
  }

  .main-cart .left {
    width: 100%;
  }

  .main-cart .₹ {
    font-size: 7px;
  }

  /* .main-cart .return-btn {
    font-size: 6px;
    height: 18px;
    width: 24%;
    border-radius: 5px;
  } */

  .main-cart .update-btn {
    font-size: 6px;
    height: 18px;
    width: 24%;
    border-radius: 5px;
  }

  .main-cart .cupon-btn {
    font-size: 6px;
    height: 18px;
    width: 30%;
    padding-left: 5px;
    border-radius: 5px;
  }
  .main-cart .left-men-image {
    width: 100%;
  }
  .main-cart .apply-btn {
    font-size: 10px;
    height: 18px;
    width: 20%;
    border-radius: 5px;
  }
  .main-cart .bottom-card h4 {
    font-size: 15px;
  }
  .main-cart .bottom-card .sub-total {
    font-size: 14px;
  }
  .main-cart .Total {
    font-size: 10px;
  }
  .main-cart .process {
    font-size: 11px;
  }
  /* .main-cart .return-btn {
    width: 30%;
    font-size: 11px !important;
    padding: 13px 5px;
  } */
  .main-cart .update-btn {
    width: 30%;
    font-size: 11px !important;
    padding: 13px 5px;
  }
  /* .main-cart .left-men-image img {
    width: 100%;
    height: 300px;
  } */
  .main-cart .header {
    margin-left: 1px;
    /* margin-right: 1px; */
    width: 100%;
  }

  .main-cart .apply-btn {
    font-size: 10px;

    width: 50%;
    border-radius: 5px;
    height: 30px;
  }
  .main-cart .cupon-btn {
    font-size: 10px !important;

    width: 40%;
    height: 30px;
    padding-left: 5px;
    border-radius: 5px;
  }
  .hhhhhhhhhhh {
    display: block;
  }
  .main-cart .left {
    width: 100%;
    /* height: 150px; */
  }
  .main-cart .men-kurta {
    font-size: 17px;
  }
  .main-cart .Lorem {
    font-size: 13px;
  }
  /* .main-cart .left-men-image img {
    width: 100%;
  } */
  .main-cart .₹ {
    font-size: 15px;
    padding: 10px;
  }
  .main-cart .left-center {
    width: 50%;
  }
  .main-cart .center {
    width: 100%;
    padding: 0px;
    margin: 5px 15px;
  }
  .main-cart .center-number {
    width: 100px !important;
    font-size: 15px;
    /* margin-left: 11px; */
  }
  .main-cart .center-number p {
    font-size: 15px;
  }
  .main-cart .right-center {
    width: 100%;
    margin: 10px 2px;
    padding-left: 0px;
  }
  .main-cart .right {
    width: 100%;
  }
  .main-cart .left-center {
    width: 100%;
  }
  #handlecart {
    margin-left: 13px;
  }
  .handle-sm-span {
    font-weight: 600;
    font-size: 16px;
  }
  .pointer {
    cursor: pointer;
    font-size: 13px;
  }
}

/* responsive 350px */
@media only screen and (max-width: 350px) {
  .home-card {
    font-size: 14px !important;
  }
  /* .main-cart .return-btn {
    width: 30%;
    font-size: 10px !important;
    padding: 13px 5px;
  } */
  .main-cart .update-btn {
    width: 30%;
    font-size: 10px !important;
    padding: 13px 5px;
  }
  .main-cart .header h5 {
    font-size: 7px !important;
  }
  .main-cart .bottom-card {
    border: 2px solid #2e2e97;
    border-radius: 7px;
    padding: 10px 20px;
    margin-left: 5px;
  }
  .main-cart .process {
    font-size: 10px !important;
  }
  .remove-card {
    font-size: 7px !important;
    padding: 5px;
  }
  .remove-coupun-button {
    width: 120px;
    font-size: 11px;
    height: 30px;
  }
}

/* responive whishlist 768 */
@media only screen and (max-width: 800px) {
  .main-cart .center-number {
    font-size: 13px;
    width: 100px;
  }
}
/* responive whishlist 350 */
@media only screen and (max-width: 350px) {
  .main-cart .center-number {
    font-size: 10px !important;
    width: 100%;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2600px) {
  .main-cart .left-men-image img {
    height: 150px;
    width: 150px;
  }
  .wishlist-image{
    height: 150px;
    width: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .main-cart .left-men-image img {
    height: 125px;
    width: 125px;
  }
  .wishlist-image{
    height: 125px;
    width: 125px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-cart .left-men-image img {
    height: 125px;
    width: 125px;
  }
  .wishlist-image{
    height: 125px;
    width: 125px;
  }
}

.cart-mobile {
  display: none;
}
@media screen and (max-width: 769px) {
  .cart-desktop {
    display: none !important;
  }
  .cart-mobile {
    display: block !important;
  }
  .main-cart .header {
    display: none;
  }
  .men-image {
    height: 150px;
    width: 150px;
  }
  .wishlist-image{
    height: 150px;
    width: 150px;
  }
  .wishlist-header{
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .wishlist-image{
    height: 100px;
    width: 100px;
  }
  .men-image {
    height: 130px;
    width: 130px;
  }
  .shadow-mobile p {
    font-size: 12px;
  }
  .main-cart .return-btn{
    padding: 5px 25px;
    height: 25px;
  }
  .main-cart .return-btn{
    padding: 5px 15px;
    height: 30px;
  }
  .process-to-checkout-btn{
    padding: 5px 15px;
    height: 30px;
  }
}
