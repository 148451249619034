.shipping-policy {
  padding: 10px 0;
}

.shipping-policy h1,
h2,
h3 {
  margin: 15px 0;
}

.shipping-policy section {
  margin: 15px 0;
  border-radius: 5px;
}

.shipping-policy p {
  font-size: 16px;
}

.shipping-policy footer {
  margin-top: 30px;
  text-align: center;
  font-size: 0.9em;
}

.shipping-policy a {
  color: #2e2e97;
  text-decoration: none;
}

.shipping-policy a:hover {
  text-decoration: underline;
}

.shipping-policy h6 {
  font-weight: 600;
}
