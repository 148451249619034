.singup {
  height: 50vh;
}

.singup-right {
  padding: 10px 150px 10px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.singup-right h3 {
  font-weight: bold;
  font-size: 26px;
}

.singup-right .enter-details {
  color: grey;
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
}

.singup-right input[type="text"] {
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  font-size: 13px;
  padding: 9px 20px;
}

.singup-right input[type="password"] {
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  font-size: 13px;
  padding: 9px 20px;
}

.singup-right .use {
  font-size: 12px;
  color: rgb(85, 82, 82);
}

.singup-right .create-btn {
  background-color: #ddd;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 600;
}

.singup-right .log-in-btn {
  height: 40px;
  background-color: #f4e3cf;
  font-size: 14px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 5px;
}

.singup-left img {
  width: 100%;
  height: 100vh;
}

.bottom-paragraph {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.singup-right .connect {
  border-left: 45px solid black;
  border-right: 45px solid black;
  height: 1px;
  font-size: 12px;
  margin: 0px;
  line-height: 0px;
  padding: 0px 0px 0px 7px;
}

.singup-right .bottom-button {
  font-size: 13px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
}

.singup-right .bottom-button i {
  font-size: 17px;
  padding-right: 15px;
}

.bottom-button img {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
}

.signup-already-account {
  color: grey;
  font-size: 13px;
}
.signup-already-account span {
  color: blue;
  cursor: pointer;
}
.signup-already-account span:hover {
  text-decoration: underline;
}

.eye-icon {
  position: absolute;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.eye-icond {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  right: 15px;
  top: 31px;
}

.singup-right form p {
  font-size: 12px;
}

.forgetpassword-right {
  padding: 10px 150px 10px 150px;
}

@media screen and (max-width: 992px) {
  .singup-right {
    height: 100vh;
  }
  .forgetpassword-right {
    height: 100vh;
  }
  .resetpassword-right{
    height: 100vh;
  }
}

@media screen and (max-width: 1440px) {
  .singup-right {
    padding: 10px 100px;
  }
  .resetpassword-right{
    padding: 10px 100px;
  }
}

@media screen and (max-width: 1024px) {
  .singup-right {
    padding: 10px 50px;
  }
  .resetpassword-right{
    padding: 10px 50px;
  }
}
@media screen and (max-width: 576px) {
  .singup-right {
    padding: 10px 30px;
  }
  .resetpassword-right{
    padding: 10px 30px;
  }
}

@media screen and (max-width: 1440px) {
  .forgetpassword-right {
    padding: 10px 100px;
  }
}

@media screen and (max-width: 1024px) {
  .forgetpassword-right {
    padding: 10px 50px;
  }
}
