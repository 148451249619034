.react-slider__areaZoom img {
  z-index: 2;
  position: relative;
}
.react-slider__areaZoom {
  position: relative !important;
}
.product_slider {
  position: relative;
  top: 0;
}

.image-gallery-container {
  position: relative;
}

.fullscreen-exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-exit-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.heart_icon_wishlist {
  position: absolute;
  z-index: 9999;
  right: 30px;
  top: 20px;
  background-color: aliceblue;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.heart_icon_wishlist svg {
  font-size: 20px;
}
.react-slider img {
  height: 100%;
}
.product_page_loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-slider__container button {
  right: 1px !important;
  background-color: #000 !important;
  opacity: 0.5 !important;
}
.react-slider__ul {
  margin: 0px !important;
  padding: 0px !important;
  justify-content: center !important;
}

.product-right-side-information {
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #2e2e97;
}

.product-right-side-information-polyester {
  color: #2e2e97;
}

.product-right-side-information-title {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
}

.product-right-side-information-title-top {
  color: #000000;
  font-size: 14px;
}

.product-right-side-information-title-tax {
  color: #000000;
  font-size: 12px;
}
.product_page_details_main{
  cursor: pointer;
  border: 1px solid grey;
  padding: 8px 10px;
}
.product_page_details_main span{
  color: #333;
  font-weight: 500;
}
.product_page_details_main svg{
  color: #333;
  margin-left: 10px;
  transition: .5s;
}
.product-right-side-information-title-base {
  color: grey;
  font-size: 14px;
}
.product-right-side-information-rating {
  background-color: #2e2e97;
  font-size: 12px;
  padding: 02px 7px;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 0px 0px;
  color: white;
  display: flex;
  gap: 2px;
}
.product-right-side-information-rating svg {
  font-size: 12px;
}
.product-right-side-information-rating-main {
  display: flex;
  align-items: center;
  color: grey;
  font-size: 12px;
}
.product-right-side-information-price {
  font-size: 20px;
  font-weight: 600;
}
.product-right-side-information-baseprice {
  font-size: 17px;
  color: grey;
  font-weight: 500;
  text-decoration: line-through;
}
.product-right-side-information-saleprice {
  font-size: 21px;
  color: #000000;
  font-weight: bold;
  padding: 0px 6px;
}
.product-right-side-information-off {
  font-size: 20px;
  font-weight: 600;
  color: #2e2e97;
}
.product-right-side-information-taxs {
  color: #2e2e97;
}
.select-size span {
  color: grey;
  cursor: pointer;
  font-size: 14px;
}
.select-size span:hover {
  text-decoration: underline;
}
.products-sizes ul li {
  align-items: center;
  border: 1px solid #2e2e97;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: center;
  list-style: none;
  padding: 7px 15px;
}
.products-sizes ul li:hover {
  list-style: none;
  border: 1px solid #2e2e97;
  background-color: #2e2e97;
  color: #fff;
}

.products-color ul li {
  list-style: none;
  border: 1px solid #2e2e97;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  /* padding: 6px 40px; */
  padding: 7px 15px 7px;
  border-radius: 5px;
}
.products-color ul li:hover {
  list-style: none;
  border: 1px solid #2e2e97;
  background-color: #2e2e97;
  color: #fff;
}

.product-page-size-chart {
  cursor: pointer;
  padding: 10px 0;
}

.product-page-size-chart h6 {
  color: #000;
}

.product-page-size-chart svg {
  color: #525252;
  font-size: 16px;
}

.add-cart-wishlist {
  background-color: #000;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 9px 11px;
  transition: all 0.5s;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.add-cart-wishlist:hover {
  background-color: #333;
}

.add-cart-button {
  background-color: #2e2e97;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 9px 11px;
  transition: all 0.5s;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.add-cart-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.add-cart-button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.add-cart-button:hover span {
  padding-right: 25px;
}

.add-cart-button:hover span:after {
  opacity: 1;
  right: 0;
}

/* .products-buttons button {
  background-color: #2e2e97 !important;
  border: 1px solid;
  border-color: #2e2e97;
  color: #ffffff !important;
  font-size: 16px;
  padding: 11px 20px;
  border-radius: 0;
  width: 100%;
  transition: all 0.5s;
}

.products-buttons button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.products-buttons button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.products-buttons button :hover span {
  padding-right: 25px;
}

.products-buttons button :hover span:after {
  opacity: 1;
  right: 0;
} */

.selected-size-main {
  border-bottom: 1px solid #2e2e97;
}

.delivery-option {
  border-bottom: 1px solid #2e2e97;
}
.delivery-option h6 {
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}
.delivery-option input {
  outline: none;
  padding: 5px 20px 5px 20px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bbb;
  min-height: 40px;
}
.delivery-option span {
  /* position: relative; */
  font-size: 14px;
  right: 80px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  background-color: #2e2e97;
  padding: 6px 14px;
  border-radius: 5px;
  margin-left: 3px;
  min-height: 40px;
}

.delivery-option ul {
  margin: 0px;
  padding: 10px 0px;
}
.delivery-option ul li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px;
}
.delivery-option ul li p {
  width: 100%;
  background-color: rgb(229, 239, 229);
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}
.delivery-option ul li img {
  width: 20px;
  height: 20px;
}
.delivery-option p {
  margin: 0px;
  padding: 10px 0px;
  font-size: 13px;
}

.product-information h6 {
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}
.product-information img {
  width: 20px;
  height: 20px;
}

.product-information p {
  font-size: 13px;
  font-weight: 600;
}
.product-information span {
  font-size: 13px;
}
.product-information svg {
  cursor: pointer;
}
.product-information-single {
  border-bottom: 1px solid #2e2e97;
  padding: 8px 0px;
}

.about-product {
  padding: 10px 0px;
  border-bottom: 1px solid #2e2e97;
}
.about-product h6 {
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}

.about-product img {
  width: 130px;
}
.about-product p {
  margin: 0px 10px;
  font-size: 13px;
}
.product-rating {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid #2e2e97;
}
.product-rating-1 h6 {
  font-size: 14px;
  font-weight: 600;
}
.product-rating-1 span {
  font-size: 32px;
}
.product-rating-1 svg {
  font-size: 18px;
  color: #2e2e97;
}
.product-rating-2 {
  width: 70%;
}

.product-rating-2 ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-rating-2 ul li svg {
  font-size: 18px;
  color: #2e2e97;
  margin: 0px 5px;
}

.product-rating-2 ul li span {
  font-size: 14px;
  margin: 0px 5px;
}

.products-page-path-title {
  font-size: 20px;
  color: grey;
}

.selectRootAttributesClass {
  background-color: #2e2e97;
  color: #fff;
}

.cart_degree {
  padding: 6px 10px;
  border: 1px solid grey;
  width: fit-content;
  /* display: inline; */
  /* border-radius: 5px; */
}

.cart_degree svg :focus {
  box-shadow: none;
  outline: 0;
}

.cart_degree svg {
  font-size: 18px;
  margin: 0px 15px;
  cursor: pointer;
}
.addcartlength-item-show {
  display: none;
  position: fixed;
  width: 100%;
  /* top:50px; */
  border: 2px solid #2e2e97;
  border-radius: 5px;
  /* padding: 10px; */
  bottom: 20px;
  background-color: #2e2e97;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: white;
}
/* .select-size {
  margin-top: 12px;
} */
.addcartlength-item-show {
  display: none;
}
#product-right-id {
  /* height: 1300px; */
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
}
#product-right-id::-webkit-scrollbar {
  display: none;
}
/* responsive 1024px */
@media only screen and (max-width: 1024px) {
  .addcartlength-item-show {
    display: none;
  }
  .react-slider {
    /* width:0px */
    width: 100% !important;
  }
  .react-slider__areaZoom {
    width: 100% !important;
  }
  .react-slider__imgZoom {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(20px);
    /* width: 500px; */
    height: 500px;
    background-repeat: no-repeat;
    border: thin solid #ccc;
    opacity: 0;
    z-index: -9999;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
  }
}

/* responsive 768    */
@media only screen and (max-width: 768px) {
  .addcartlength-item-show {
    display: none;
  }
  .react-slider {
    /* width:0px */
    width: 100% !important;
  }
  .products-color ul li {
    /* padding: 4px 20px; */
    padding: 7px 15px 7px;
  }
  .react-slider__areaZoom {
    width: 100% !important;
  }
  .react-slider__imgZoom {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(20px);
    /* width: 500px; */
    height: 500px;
    background-repeat: no-repeat;
    border: thin solid #ccc;
    opacity: 0;
    z-index: -9999;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
  }
  #product-right-id {
    height: auto;
    overflow-y: none;
  }
}
/* responsive 525*/
@media only screen and (max-width: 525px) {
  .react-slider__imgZoom {
    display: none;
  }
  .addcartlength-item-show {
    width: 93%;
    display: flex;
  }
  .products-buttons button {
    padding: 10px 30px;
  }
}

.addReviewPhone {
  display: none;
}

@media only screen and (max-width: 992px) {
  .react-slider__imgZoom {
    display: none !important;
  }
  .react-slider__lens {
    display: none !important;
  }
  .addReviewPhone {
    display: block;
  }
  .addReviewLaptop {
    display: none;
  }
}
