.card_1 {
  /* background-image: url(/public/img/card_1.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15% 7%;
  height: 100%;
  background-position: right;
}

.card_1 span {
  color: rgba(87, 89, 203, 1);
}

.card_1 h2 {
  font-weight: bold;
  line-height: 45px;
  font-size: 38px;
}

.card_1 h5 {
  font-weight: bold;
  margin-top: 8px;
}

.card_2 {
  /* background-image: url(/public/img/card_2.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15% 7%;
  height: 100%;
  background-position: right;
}

.card_2 h2 {
  font-weight: 900;
  line-height: 45px;
  font-size: 38px;
  color: rgb(46, 46, 151);
}

.card_2 h5 {
  font-weight: bold;
  margin-top: 8px;
}

.card_1-div,
.card_2-div {
  width: 70%;
}

@media (max-width: 992px) {
  .card_1,
  .card_2 {
    padding: 23% 7% 7%;
  }
}

@media (max-width: 768px) {
  .card_1,
  .card_2 {
    padding: 13% 8% 20%;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .card_1 h2 {
    font-size: 24px;
    line-height: 28px;
  }
  .card_1 h5 {
    font-size: 12px;
  }
  .card_2 h2 {
    line-height: 28px;
    font-size: 24px;
  }
  .card_2 h5 {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .card_1 h2 {
    line-height: 34px;
    font-size: 27px;
  }
  .card_1 h5 {
    font-size: 12px;
  }
  .card_2 h2 {
    line-height: 34px;
    font-size: 27px;
  }
  .card_2 h5 {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .card_1 h2 {
    line-height: 26px;
    font-size: 24px;
  }
  .card_1 h5 {
    font-size: 12px;
  }
  .card_1 {
    background-position: 95%;
  }
  .card_2 {
    background-position: 83%;
  }
  .card_2 h2 {
    line-height: 26px;
    font-size: 24px;
  }
  .card_2 h5 {
    font-size: 12px;
  }
}
