.return-policy p {
  font-size: 16px;
}

.return-policy ul li {
  font-size: 16px;
}

.return-policy ol li {
  font-size: 16px;
}

.return-policy a {
  color: #2e2e97;
  text-decoration: none;
}

.return-policy a:hover {
  text-decoration: underline;
}

.return-policy h6 {
  font-weight: 600;
}
