.about-ul li {
  list-style-type: disc;
}
.about-ul li p {
  font-weight: 400;
}

.about-us a {
  color: #2e2e97;
  text-decoration: none;
}

.about-us a:hover {
  text-decoration: underline;
}

.about-us p {
  font-size: 16px;
}

.about-us h6 {
  font-weight: 600;
}

#strengths ul li {
  font-size: 16px;
}
