.sale1 {
  /* background-image: url(/public/img/sale1.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12% 7%;
  height: 100%;
  background-position: center;
  border-radius: 5px;
}

.sale2 {
  /* background-image: url(/public/img/sale2.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12% 7%;
  height: 100%;
  background-position: center;
}

.sale-content {
  width: 50%;
}

.sale1 h3 {
  font-size: 28px;
  font-weight: bold;
}

.sale1 span {
  color: #2e2e97;
  font-weight: bolder;
}

.sale1 p {
  color: #00000080;
  font-size: 14px;
  margin: 20px 0px;
}

.sale1 button {
  font-size: 12px;
  background-color: #2e2e97;
  padding: 10px 25px;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
}

@media (max-width: 1400px) {
  .sale1 h3 {
    font-size: 23px;
  }
  .sale1 p {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .sale1,
  .sale2 {
    background-position: 68%;
  }
  .sale1 h3 {
    font-size: 20px;
  }
  .sale1 p {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .sale1,
  .sale2 {
    background-position: 77%;
    margin: 0px !important;
  }
  .sale1 h3 {
    font-size: 20px;
  }
  .sale1 p {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  .sale1,
  .sale2 {
    background-position: 68%;
  }
}
@media (max-width: 425px) {
  .sale1,
  .sale2 {
    background-position: 75%;
  }
  .sale1 h3 {
    font-size: 20px;
  }
  .sale1 p {
    font-size: 12px;
  }
  .sale-content {
    width: 70%;
  }
}
